import React from "react";
import { Link } from "react-router-dom";
import { zoho1 } from "../../Componants/Image/ImportImg";

function HomeAbout() {
  return (
    <div className="row my-5 justify-content-center">
      <div className="col-lg-10">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4 d-none d-lg-block">
            <img src={zoho1} alt="" className="img-fluid" />
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8">
            <h1 className="fw-bold display-5 color">
              Who We Are At Bharat Business Innovations
            </h1>
            <div className="content-center">
              <div>
                <p>
                  We are a team of dedicated professionals passionate about
                  delivering innovative solutions tailored to meet your business
                  needs. Our mission is to empower businesses by providing
                  top-notch services that drive growth, efficiency, and success.
                  With expertise across various industries, we pride ourselves
                  on our ability to understand and address complex challenges.
                </p>
                <p>
                  We believe in building strong partnerships with our clients,
                  working collaboratively to achieve their goals and exceed
                  expectations. Our commitment to excellence, combined with a
                  deep understanding of evolving technologies, positions us as a
                  trusted partner in today’s competitive market. Your success is
                  our priority.
                </p>
                <Link to="/about">
                  <button className="btn-style-3">Read More</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 my-0 my-lg-5 ">
            <div className="rounded bg-color2 p-3 shadow-sm my-2">
              <h1 className="fw-bold display-6 color">Our Mission</h1>
              <p className="fs-5 fw-bold">
                We are on a mission to transform 100 businesses by simplifying
                and innovating complex business processes, for business growth &
                sustainability
              </p>
            </div>
            <div className="rounded bg-color-second p-3 shadow-sm">
            <h1 className="fw-bold display-6 color">Our Vision</h1>
              <p className="fs-5 fw-bold">
                Our vision is to make Bharat’s Businesses a global choice of a
                business partner.
              </p>
            </div>
          </div>

          <div className="col-lg-6 my-0 my-lg-5">
            <div className="rounded bg-color3 p-3 shadow-sm my-2">
            <h1 className="fw-bold display-6 color">Our Values</h1>
              <p className="fs-5 fw-bold">
                {" "}
                <b>B</b> - Business First
              </p>
              <p className="fs-5 fw-bold">
                <b>H</b> - High Efficiency
              </p>
              <p className="fs-5 fw-bold">
                <b>A</b> - Advanced & Innovative
              </p>
              <p className="fs-5 fw-bold">
                <b>R</b> - Result Oriented
              </p>
              <p className="fs-5 fw-bold">
                <b>A</b> - Actions with Accountability
              </p>
              <p className="fs-5 fw-bold">
                <b>T</b> - Trust & Transparent
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAbout;
