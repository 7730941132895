import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container-fluid bgImage">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">Privacy Policy</h1>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <h1 className="text-center fw-bold display-5 color">PRIVACY POLICY</h1>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              <strong>INTRODUCTION</strong><br/>
              Thank you for visiting our website and choosing to be part of our community at Bharat Business Innovations Pvt Ltd. We are dedicated to safeguarding your information privacy and treating the information you share with us with utmost respect and sensitivity. This privacy policy applies to all our websites. This Privacy Policy describes the types of information that we collect, record as well as how we utilise it. Please contact us if you have any more concerns or need more information about our Privacy Policy. We’ve updated our Privacy Policy and improved our privacy practices so we can better safeguard your data. This Site also uses cookies to personalize your experience and target advertising. This policy is to be read in conjunction with our terms and conditions and our Cookies Policy. By continuing to use our Site, you accept the terms of this Privacy Policy, our terms and conditions and our Cookies Policy. Please note that if you disable cookies you may not be able to use all the features of our site. This Privacy Policy solely applies to our online operations and is applicable to visitors to our website in relation to information provided and/or collected by Bharat Business Innovations Pvt Ltd. The Website and our other programs and services are hereby referred to as the “Services”. This policy does not apply to any information gathered offline or through methods other than this website.
            </p>
            <p>
              <strong>DEFINITIONS AND KEY TERMS</strong><br/>
              <strong>Cookie:</strong> A small quantity of information produced by a website and kept by your web browser. It is used to identify your browser, give statistics, and remember personal information about you, such as your language selection or login information.<br/>
              <strong>Customer:</strong> Refers to the company, organization, or person that signs up to use our services.<br/>
              <strong>Device:</strong> Any electronic gadget such as a phone, tablet, computer, laptop, or any other like gadget that can be used to visit Bharat Business Innovations Pvt Ltd and use the services.<br/>
              <strong>I.P. Address:</strong> Every device connected to the Internet is assigned a number known as an Internet Protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.<br/>
              <strong>Personnel:</strong> Refers to those individuals who are associated with Bharat Business Innovations Pvt Ltd in any capacity whatsoever or are under contract to perform a service on behalf of Bharat Business Innovations Pvt Ltd.<br/>
              <strong>Personal Data:</strong> Any information that directly, indirectly, or in connection with other information allows for the identification or identifiability of a natural person.<br/>
              <strong>Service/Services:</strong> Refers to any service provided by Bharat Business Innovations Pvt Ltd as described in the relative terms and on this platform.
            </p>
            <p>
              <strong>INFORMATION YOU GIVE US:</strong><br/>
              We gather personal information that you submit on our Site or otherwise voluntarily provide to us when you contact us to make purchases, seek information, or register for and/or attend our activities and events, among other things. You can opt not to supply certain information, but you may be unable to use many of our features as a result of not providing such information. Personal Information is divided into several categories, which includes:
            </p>
            <ul>
              <li><strong>Identifiers:</strong> Name, Address, Email, Phone</li>
              <li><strong>Geolocation Data:</strong> GPS coordinates, Location history</li>
              <li><strong>Professional Data:</strong> CV, resume, employment history</li>
              <li><strong>Education Data:</strong> Educational background, grades, scores</li>
              <li><strong>Payment Data:</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Zoho. You may find their privacy notice here: <a href="https://www.zoho.com/privacy.html" target="_blank" rel="noopener noreferrer">https://www.zoho.com/privacy.html</a>.</li>
            </ul>
            <p>
              Overall, business coaching provides individuals with personalized support and guidance to maximize their potential and achieve their business goals.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
