import React from "react";

const TermAndCondition = () => {
  return (
    <>
      <div className="container-fluid bgImage">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">Terms of Use</h1>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center fw-bold display-5 color">TERMS OF USE</h1>
            <section className="mt-4">
              <h2 className="fw-bold">OVERVIEW</h2>
              <p>
                Welcome to our website. By continuing to use and browse our
                website, you acknowledge that you have read, understood, and
                unconditionally agree to be bound by the terms contained in this
                policy, including the privacy policy which will govern your
                relationship with Bharat Business Innovations Pvt Ltd (“BBI”). If
                you disagree with any part of these terms and conditions or the
                privacy policy, we urge you not to use our website or any other
                site, database, page, network, etc. ("Platform") owned and
                operated by BBI.
              </p>
              <p>
                The domain name and/or content on the platforms is owned and
                operated by BBI, having its registered office at: WeWork Enam
                Sambhav, C - 20, G Block Rd, G Block BKC, Bandra Kurla Complex,
                Bandra East, Mumbai, Maharashtra 400051.
              </p>
              <p>
                These Terms are a legally binding agreement between you and BBI
                that governs your use of the Platform and any related products or
                services operated, offered, sold, or otherwise provided by BBI
                through its Platforms (collectively, the “Services”). The content
                of the pages of this website is for your general information and
                use only and is subject to change without notice.
              </p>
            </section>

            <section className="mt-4">
              <h2 className="fw-bold">ELIGIBILITY</h2>
              <p>
                By registering, visiting, or using the Services and/or the
                platform, you hereby represent and warrant to BBI that you are 18
                years of age or older, and that you have the right, authority,
                and capacity to use the Services and browse the platform and
                agree to abide by these Terms.
              </p>
              <p>
                If you are below 18 years of age, it is assumed that you are
                using/browsing the Platform under the supervision of your parent
                or legal guardian and that your parent or legal guardian has read
                and agreed to these Terms.
              </p>
              <p>
                Should BBI become aware that you are under the age of 18 and are
                using/browsing the Platform without the supervision of your
                parent or legal guardian, BBI reserves the right to deactivate
                your account and suspend any further browsing without notice.
              </p>
            </section>

            <section className="mt-4">
              <h2 className="fw-bold">ACCOUNT INFORMATION</h2>
              <p>
                Access to and use of certain functionalities of the Platform may
                require you to either register using a user account (“Account”)
                with us or to subscribe to certain products and services being
                offered. You agree to provide us with accurate and complete
                information for this purpose and you agree to update all
                information provided to us if and as soon as such information
                changes.
              </p>
              <p>
                You agree to keep your log-in information confidential and to not
                authorize any third party to use your Account. BBI will not be
                liable for any loss or damage that results from the unauthorized
                use of your Account, either with or without your knowledge. You
                agree that we may attribute all use of your Account to you and
                hold you liable for the same.
              </p>
              <p>
                BBI reserves the right to cancel or suspend your Account for any
                reason, at any time, and without assigning any reason for the
                same.
              </p>
            </section>

            <section className="mt-4">
              <h2 className="fw-bold">PRIVACY PRACTICES</h2>
              <p>
                The privacy Policy of BBI can be viewed <a href="#">Here</a>.
                The Privacy Policy is a constituent of the present terms of use
                and as such must be read in conjunction.
              </p>
            </section>

            <section className="mt-4">
              <h2 className="fw-bold">LIMITATION OF LIABILITY</h2>
              <p>
                BBI does not provide any warranty or guarantee as to the
                accuracy, timeliness, performance, completeness, or suitability
                of the information and materials found or offered on this website
                for any particular purpose. You acknowledge that such information
                and materials may contain inaccuracies or errors and we expressly
                exclude liability for any such inaccuracies or errors to the
                fullest extent permitted by law.
              </p>
              <p>
                BBI may provide you with information about the services being
                offered by them that you are either already using, or have
                enquired about, or that may interest you or any combination
                thereof. You agree that upon registering on the Platform, you
                grant permission to BBI to contact you by any electronic means to
                inform you and offer these services. You may at any point
                withdraw the said consent.
              </p>
              <p>
                To the extent permitted by law, in no event shall BBI and its
                affiliates, mentioned on the Platform, be liable for any
                incidental, direct, indirect, exemplary, punitive, and/or
                consequential damages, lost profits, and/or damages resulting
                from lost data or business interruption resulting from the use of
                and/or inability to use the Platform, the products, the services
                or the content whether based on warranty, contract, tort, or any
                other legal foundation, and whether or not BBI had advised of the
                possibility of such damages.
              </p>
              <p>
                The information contained on any of the websites does not amount
                to professional advice, suggestions or advice and BBI shall not
                be liable for any consequences arising out of the misapplication
                of the information provided on the website and the user is urged
                to seek professional guidance regarding the same.
              </p>
            </section>

            {/* Additional sections like LICENSE AND OWNERSHIP, RESTRICTIONS, LINKS, etc. 
                can be added similarly as per the above pattern */}

            <section className="mt-4">
              <h2 className="fw-bold">GOVERNING LAW AND JURISDICTION</h2>
              <p>
                The transactions on this platform shall be governed by, construed
                interpreted, and enforced in accordance with the laws of India.
                Specific performance and damages or any other disputes arising in
                respect hereto shall be subject to exclusive jurisdiction of the
                courts of Pune.
              </p>
            </section>

            <section className="mt-4">
              <h2 className="fw-bold">DISCLAIMER</h2>
              <p>
                Any and all claims or representations as to income earnings made
                on our websites, our events, our training programs or in our
                materials or information are not to be considered as average
                earnings. Testimonials are not representative. There can be no
                assurances that any prior successes, or past results can be used
                as an indication of your future success or results and we make no
                such guarantee. If you rely upon our testimonials or figures, you
                do so at your own risk, and you accept all risk associated with
                your reliance.
              </p>
              <p>
                You agree that our company is not responsible for the success or
                failure of your business or personal decisions relating to any
                information presented by BBI or our programs, products and/or
                services.
              </p>
              <p>
                Neither BBI nor its partners, officers, directors, employees,
                subsidiaries, affiliates, licensors, service providers, content
                providers, consultants and agents are financial advisers, and
                nothing contained on the website is intended to be construed as
                financial advice. BBI is not an investment advisory service, is
                not an investment adviser, and does not provide personalized
                financial advice or act as a financial advisor. The website
                exists for educational purposes only, and the materials and
                information contained herein are for general informational
                purposes only.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermAndCondition;
