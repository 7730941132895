import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { logo, zoho } from "../Image/ImportImg";

function Navbar() {
  const [progress, setProgress] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const scrollPercentage =
        (scrollPosition / (bodyHeight - windowHeight)) * 100;
      setProgress(scrollPercentage);

      // Toggle sticky navbar
      if (scrollPosition > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuItems = [
    { name: "Home", href: "/" },
    { name: "Services", href: "service" },
    { name: "Career", href: "https://www.expertia.ai/bharatbusinessipl" },
    { name: "Case Study", href: "blog" },
    { name: "About Us", href: "about" },
    { name: "Contact Us", href: "contact" },
  ];

  const menuItemsPhone = [
    { name: "Home", href: "/" },
    { name: "Services", href: "service" },
    { name: "Career", href: "https://www.expertia.ai/bharatbusinessipl" },
    { name: "Case Study", href: "blog" },
    { name: "About Us", href: "about" },
    { name: "Contact Us", href: "contact" },
  ];

  return (
    <div>
      <LoadingBar
        color="#00008B"
        height={3}
        shadow={`0px 0px 10px #00008B`}
        progress={progress}
      />
      <nav
        className={`navbar navbar-expand-lg bg-nav shadow-sm py-2 m-0 bg-white ${
          isSticky ? "navbar-sticky" : ""
        }`}
      >
        <div className="container-fluid">
          <a className="navbar-brand logo" href="/">
            <img src={logo} alt="" width="auto" height={100} className="img-fit"/>
          </a>
          <a className="navbar-brand logo d-none d-lg-block" href="/">
            <img src={zoho} alt="" width="auto"  />
          </a>
          <i
            className="bi bi-list fs-1 d-lg-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          ></i>

          <div className={`collapse navbar-collapse`} id="navbarNav">
            <ul className="navbar-nav">
              {menuItems.map((item, index) => (
                <li className="nav-item" key={index}>
                  <NavLink
                    className={({ isActive }) =>
                      `nav-link ${
                        isActive ? "navbar-pill" : "text-black"
                      } hover:text-pink-500`
                    }
                    aria-current="page"
                    to={item.href}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      {/* mobile menu */}

      <>
        <div
          className="offcanvas offcanvas-start"
          style={{width:"280px"}}
          tabIndex={-1}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
          <a className="navbar-brand logo" href="/">
            <img src={logo} alt="" width="auto" height={100} className="img-fit"/>
          </a>
         
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            {menuItemsPhone.map((item, index) => (
              <span className="nav-item fs-1 fw-bold" data-bs-dismiss="offcanvas"
              aria-label="Close" key={index}>
                <NavLink
                  style={{ fontSize: "20px" }}
                  className={({ isActive }) =>
                    `${isActive ? "navbar-mobile" : "text-dark"}`
                  }
                  aria-current="page"
                  to={item.href}
                >
                  <i className={`bi bi-${item.icon}`}></i> <br /> {item.name}
                </NavLink>
              </span>
            ))}
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar;
