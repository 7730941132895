import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getlead } from "../../Componants/api/api_base";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

function GetQuatation() {
  const {id} = useParams()
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    company: "",
    description: "",
    slug:id,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${getlead}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccess(true);
      toast.success("Thanks for contacting us!");

      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 2000);
    } catch (error) {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <button
        className="btn-style"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Contact Us
      </button>
      {/* modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {id.replace(/_/g, " ")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <h3 className="text-center color fw-bold">Send Us a Message</h3>
                <div className="my-4">
                  <input
                    type="text"
                    required
                    name="name"
                    placeholder="Name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <input
                    type="text"
                    required
                    name="number"
                    placeholder="Phone Number"
                    className="form-control"
                    value={formData.number}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <input
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <input
                    required
                    name="company"
                    type="text"
                    placeholder="Company"
                    className="form-control"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <textarea
                    name="description"
                    className="form-control"
                    placeholder="Message"
                    rows="3"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
               
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn-style"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="spinner-border color" size="sm" />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetQuatation;
