import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { gesture } from "../../Componants/Image/ImportImg";
import { singleservicefeature } from "../../Componants/api/api_base";
import { useParams } from "react-router-dom";

function ServiceFeature() {
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    // Fetch features from the API
    axios
      .get(`${singleservicefeature}/${id}`)
      .then((response) => {
        setFeatures(response.data); // Set the data
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error("Error fetching features:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [id]); // Add `id` as a dependency

  return (
    <div className="row my-5 justify-content-center">
      <div className="col-lg-10">
        <div className="row">
        {features.length > 0 && (
            <h1 className="text-center fw-bold">
              {id.replace(/_/g, " ")}
            </h1>
          )}
        
          {loading ? (
            // Display Skeleton Loader while loading
            Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="col-sm-12 col-md-5 col-lg-5 my-2">
                <div className="d-flex align-items-center">
                  <Skeleton
                    circle={true}
                    height={30}
                    width={25}
                    className="mx-2"
                  />
                  <Skeleton width={150} />
                </div>
              </div>
            ))
          ) : features && features.length > 0 ? (
            // Display features once loaded
            features.map((feature) => (
              <div key={feature._id} className="col-sm-12 col-md-5 col-lg-5 my-2">
                <div className="d-flex align-items-center">
                  <img
                    src={gesture}
                    alt="Feature icon"
                    height={30}
                    width={25}
                    className="mx-2 rounded"
                  />
                  <span className="fs-5 fw-bold">
                    {feature.title}
                  </span>
                </div>
              </div>
            ))
          ) : (
            // Handle case where there are no features
            <p>{}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceFeature;
