import React, { useState, useEffect } from "react";
import { getSingleBlog } from "../../Componants/api/api_base";
import { useParams } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SingleBlog = () => {
  const { id } = useParams();
  const [singleblogs, setSingleBlogs] = useState({});
  const [loading, setLoading] = useState(true);

  const getBlogApi = async () => {
    try {
      const response = await axios.get(`${getSingleBlog}/${id}`);
      setSingleBlogs(response.data);
    } catch (error) {
      console.log("Error fetching blog data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogApi();
  }, [id]);

  // Update the document title and meta description dynamically
  useEffect(() => {
    if (!loading && singleblogs.metaTitle && singleblogs.metaDescription) {
      // Update tab title (main tab hover title)
      document.title = singleblogs.metaTitle;

      // Update meta description dynamically
      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", singleblogs.metaDescription);
      } else {
        // If the meta description tag doesn't exist, create it
        const newMetaDescription = document.createElement("meta");
        newMetaDescription.name = "description";
        newMetaDescription.content = singleblogs.metaDescription;
        document.head.appendChild(newMetaDescription);
      }
    }
  }, [singleblogs.metaTitle, singleblogs.metaDescription, loading]);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 my-5">
            {loading ? (
              <>
                <Skeleton width={"60%"} height={40} className="mb-4 mx-auto" />
                <Skeleton
                  height={400}
                  width={"100%"}
                  className="my-4 rounded"
                />
                <Skeleton count={5} className="mt-3" />
              </>
            ) : (
              <>
                <h1 className="text-center">{singleblogs.blogTitle}</h1>
                <div className="text-center">
                  <img
                    src={singleblogs.image}
                    alt={singleblogs.blogTitle || "Blog Image"}
                    className="img-fit my-4 rounded"
                    height={400}
                    width={"100%"}
                  />
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: singleblogs.description }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
