export const base_url = "https://bbl-backend.vercel.app/";
export const getAllBlogs = `${base_url + "api/blog/all"}`;
// service
export const getAllservice = `${base_url + "api/serviceCategory/all"}`;
export const singleservicebanner = `${base_url + "api/subService/banner/all"}`;
export const singleserviceabout = `${base_url + "api/subService/contact/all"}`;
export const singleservicecrm = `${base_url + "api/subService/crm/all"}`;
export const singleservicewhychoose = `${
  base_url + "api/subService/reason/all"
}`;
export const singleservicefeature = `${
  base_url + "api/subService/feature/all"
}`;

//
export const getAllTeam = `${base_url + "api/team/all"}`;
export const Contact = `${base_url + "api/contact/create"}`;
export const getlead = `${base_url + "api/booking/create"}`;
export const PropertyApi = `${base_url + "api/property/all"}`;
export const getSingleProperty = `${base_url + "api/property/single"}`;
export const RoomApi = `${base_url + "api/room/all"}`;
export const BookRoomApi = `${base_url + "api/booking/book"}`;
export const reviewApi = `${base_url + "api/review/all"}`;
export const Client = `${base_url + "api/client/all"}`;
export const getSingleBlog = `${base_url + "api/blog"}`;
export const Registration = `${base_url + "api/user/register"}`;
export const Login = `${base_url + "api/user/login"}`;
export const userProfile = `${base_url + "api/user/profile"}`;
export const userhistory = `${base_url + "api/booking/user"}`;
export const banner = `${base_url + "api/gallery/all"}`;

export const getAllTermsAndConditions = `${
  base_url + "api/termsCondition/all"
}`;
