import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { singleserviceabout } from "../../Componants/api/api_base";
import { useParams } from "react-router-dom";
import GetQuatation from "./GetQuatation";

function SingleServiceAboutus() {
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(`${singleserviceabout}/${id}`);
        setServiceData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the service data:", error);
        setLoading(false);
      }
    };

    fetchServiceData();
  }, [id]);

  // Check if data is still loading
  if (loading) {
    return (
      <div className="row my-5 justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Skeleton height={400} className="mb-3" />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <div className="">
                <Skeleton height={50} width={200} className="mb-3" />
                <Skeleton count={2} height={20} className="mb-2" />
                <Skeleton width={150} height={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Ensure serviceData is an array and has data
  const service = Array.isArray(serviceData) ? serviceData[0] : null;

  return (
    <div>
      {service?.description && (
        <div className="row my-5 justify-content-center">
          <div className="col-lg-10">
            <div className="row justify-content-between">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <img src={service?.image} className="img-fluid rounded" alt="Service" />
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7 content-center">
                <div>
                  <h2 className="color fw-bold">{service?.title}</h2>
                  <p dangerouslySetInnerHTML={{ __html: service.description }}></p>
                  <GetQuatation />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleServiceAboutus;
