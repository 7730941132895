import React from "react";
import {
  review1,
  review2,
  review3,
  review4,
  review5,
  review6,
} from "../../Componants/Image/ImportImg";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

function ServiceWorkflow() {
  const { id } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Optional: Adjust the speed as necessary
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const reviews = [review1, review2, review3, review4, review5, review6];

  return (
    <>
      {id === "HR_Hiring" && (
        <div className="row my-5 justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Slider {...settings}>
                  {reviews.map((review, index) => (
                    <div key={index}>
                      <img
                        src={review}
                        className="d-block w-100 img-fit rounded border"
                        alt={`Review ${index + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 content-center">
                <div>
                  <h1 className="color fw-bold my-2">Testimonials</h1>
                  <p>
                    In today's project management landscape, simplicity reigns
                    supreme. TechAzora tasks offer a straightforward, effective
                    solution that avoids overwhelming complexity. With intuitive
                    features, it keeps both you and your clients focused on what
                    matters most: achieving goals efficiently.
                  </p>
                  <a
                    href="https://drive.google.com/file/d/1uodEJd2-5DcqQRHYPhPoGpD62sqaz4O-/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn-style">
                      See Our Hiring Portfolio
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServiceWorkflow;
