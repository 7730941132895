import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAllTermsAndConditions } from "../../Componants/api/api_base";
 
const TermsAndCondition = () => {
    // const { id } = useParams();
    const [singleTandC, setsingleTandC] = useState([]);
    console.log(singleTandC);
    const getTandcApi = async () => {
      try {
        const response = await axios.get(`${getAllTermsAndConditions}`);
        setsingleTandC(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
        getTandcApi();
    }, []);
    console.log(singleTandC)
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 py-5 mt-5 ">
                <h1>Term & Condition</h1>
             {
              singleTandC.map((item)=>(
                <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
              ))
             }
            </div>
          </div>
        </div>
      </div>
    );
    }
export default TermsAndCondition
