import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { reviewApi } from "../../Componants/api/api_base";
import userImage from "../../Images/user.avif";

function HomeReview({ searchreview }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [review, setreviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const getreviewApi = async () => {
    try {
      const response = await axios.get(
        `${reviewApi}?page=&limit=&searchQuary=${searchreview}`
      );
      setreviews(response.data);
    } catch (error) {
      console.error("Error fetching review:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getreviewApi();
  }, [searchreview]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="container my-5">
      <h1 className="text-center fw-bold display-5 my-3 color">
        What Customer Say About us
      </h1>
      <Slider {...settings}>
        {review.map((review, index) => (
          <div key={index} className="p-3">
            <div className="rounded-4 bg-color p-3 text-white">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  {review.image ? (
                    <img
                      src={review.image}
                      className="img-fit rounded-circle"
                      height={60}
                      width={60}
                      alt=""
                    />
                  ) : (
                    <img
                      src={userImage}
                      className="img-fit rounded-circle"
                      height={60}
                      width={60}
                      alt=""
                    />
                  )}
                  <h5 className="fw-bold me-2 mt-2 mx-2">{review.name}</h5>
                </div>
                <div className="mt-2">
                  {Array.from({ length: review.rating }, (_, index) => (
                    <i key={index} className="bi bi-star-fill text-warning"></i>
                  ))}
                </div>
              </div>
              <p>{review.position}</p>
              <p
                className="text-white"
                dangerouslySetInnerHTML={{ __html: review.description }}
              ></p>
              {/* <div className="text-end">
                <small className="text-end text-white">
                  Posted {formatDate(review.createdAt)}
                </small>
              </div> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default HomeReview;
