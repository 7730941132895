import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import Skeleton from "react-loading-skeleton"; // Import Skeleton
import { singleservicewhychoose } from "../../Componants/api/api_base";
import { useParams } from "react-router-dom";

function WhyChooseService() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const { id } = useParams();

  useEffect(() => {
    // Use Axios to fetch data
    axios
      .get(`${singleservicewhychoose}/${id}`)
      .then((response) => {
        setServices(response.data); // Directly set the array of service objects
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setLoading(false); // Set loading to false in case of error
      });
  }, [id]); // Add `id` as a dependency to refetch when it changes

  return (
    <div className="row my-5 justify-content-center py-5">
      <div className="col-lg-10">
        <div className="row">
          {services.length > 0 && (
            <h1 className="text-center fw-bold color">
              Why Choose {id.replace(/_/g, " ")}
            </h1>
          )}

          {loading ? (
            // Show skeleton loader while loading.
            Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className="col-sm-12 col-md-4 col-lg-4 text-center my-3"
              >
                <Skeleton circle height={50} width={50} className="bg-gray" />
                <Skeleton height={20} width={120} className="my-2" />
                <Skeleton height={15} width={100} />
              </div>
            ))
          ) : // Check if `services` array is not empty
          services.length > 0 ? (
            services.map((service, index) => (
              <div
                key={service._id}
                className="col-sm-12 col-md-4 col-lg-4 text-center my-3"
              >
                <span
                  style={{ width: "50px", height: "50px" }}
                  className="rounded-circle text-white bg-color p-2"
                >
                  {String(index + 1).padStart(2, "0")}
                </span>
                <h5 className="my-2 fw-bold color">{service.title}</h5>
                <p
                  dangerouslySetInnerHTML={{ __html: service.description }}
                ></p>
              </div>
            ))
          ) : (
            <p className="text-center">No services available.</p> // Optionally handle empty state
          )}
        </div>
      </div>
    </div>
  );
}

export default WhyChooseService;
