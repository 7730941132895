import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Blog.css";
import { getAllBlogs } from "../../Componants/api/api_base";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BlogApi = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const getBlogApi = async () => {
    try {
      const response = await axios.get(
        `${getAllBlogs}`
      );
      
      // Log the full response for debugging purposes
      console.log("API Response:", response.data);

      // Ensure blogs is an array, fallback to empty array if not
      setBlogs(Array.isArray(response.data.blogs) ? response.data.blogs : []);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      // Handle errors more gracefully
      setBlogs([]); // fallback to an empty array in case of error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlogApi();
  },[]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="row">
      {loading
        ? Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="col-lg-4 my-4">
              <div className="rounded bg-color-second">
                <Skeleton height={200} className="rounded img-fit-blog" />
                <div className="p-2">
                  <Skeleton width={100} height={20} />
                  <Skeleton height={30} style={{ marginTop: 10 }} />
                </div>
              </div>
            </div>
          ))
        : blogs && blogs.length > 0 ? (
          blogs.map((blog, index) => (
            <div className="col-lg-4 my-4" key={blog._id || index}>
              <Link
                to={`/${blog.slugUrl}`}
                className="text-decoration-none text-black"
              >
                <div className="rounded bg-color-second">
                  <img
                    src={blog.image}
                    alt={blog.blogTitle || "Blog Image"}
                    className="rounded img-fit-blog"
                  />
                  <div className="p-2">
                    <i className="fw-bold text-secondary">
                      Uploaded on - {formatDate(blog.createdAt)}
                    </i>
                    <h1 className="fs-4 pt-2 pb-2 fw-bold">
                      {blog.blogTitle && blog.blogTitle.length > 50
                        ? `${blog.blogTitle.slice(0, 50)}...`
                        : blog.blogTitle || "No Title Available"}
                    </h1>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="col-12 text-center">
            <p>No blogs found.</p>
          </div>
        )}
    </div>
  );
};

export default BlogApi;
