import React from "react";
import { about1,about2,about3 } from "../../Componants/Image/ImportImg";
import Team from "./Team";

const About = () => {
  return (
    <>
      <div className="container-fluid bgImage">
        <div className="row justify-content-center ">
          <div className="col-lg-12 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">
              About us
            </h1>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <h1 className="text-center fw-bold display-5 color">
          Professionals Ready To Help Grow Your Business
          </h1>
          <div className="col-sm-12 col-md-5 col-lg-5 pt-5 about-us-image">
            <img src={about3} alt="" className="img-fluid" />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 pt-3 content-center">
            <div>
              <h4>The Best IT Solution with 40+ Years of Experience! Zoho Expertise for Over a Decade!</h4>
              <p>
              Founded in 2023 by Saandeep N Patkar and Amol Salunkhe, Bharat Business Innovations Pvt Ltd (BBI) is an official Zoho Partner dedicated to helping businesses thrive in today’s competitive market. With over 40 years of combined industry experience and more than 10 years of Zoho expertise, we bring unparalleled knowledge and innovative solutions to the table.
              </p>
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 order-lg-2 pt-5 about-us-image">
            <img src={about2} alt="Aboutus" className="img-fluid" />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 order-lg-1 pt-3 content-center">
            <div>
             
              <p>
              At BBI, our mission is to empower businesses by addressing their unique challenges and providing tailored solutions that foster sustainable growth. We specialize in a range of services designed to meet the diverse needs of our clients:
              </p>
              <p>
              <b>Zoho Consulting</b>: Expert guidance to help you leverage Zoho's full potential for your business operations.
              </p>
              <p><b>Talent Acquisition and Management</b>: Through TSR Recruitment, we help you build and manage a workforce that drives your business forward.</p>
              <p><b>Customer Engagement Solutions</b>: With BotPe's WhatsApp automation, we ensure seamless customer interactions, enhancing your engagement and customer satisfaction.</p>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-12 col-md-5 col-lg-5 pt-5 about-us-image">
            <img src={about1} alt="" className="img-fluid" />
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 pt-3 content-center">
            <div>
              <p>
              We understand the common hurdles businesses face, including limited resources, infrastructure constraints, and a lack of expertise. Our team is committed to providing the tools and support necessary to overcome these challenges, enabling businesses to scale their operations and expand their market reach.
              </p>
              <p>
              At BBI, we believe every business has the potential to succeed. We are here to be your trusted partner in overcoming obstacles, driving growth, and realizing your business goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Team/>
    </>
  );
};

export default About;
