import React from "react";

const RefundAndCancellation = () => {
  return (
    <>
      <div className="container-fluid bgImage">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">
              Refund & Cancellation
            </h1>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <h1 className="text-center fw-bold display-5 color">
            Refund and Cancellation
          </h1>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              By making a purchase from the website, you unconditionally agree
              to all the terms and conditions.
            </p>
            <p>
              Bharat Business Innovations Pvt Ltd website operates within a
              strict{" "}
              <strong>No Exchange and No Refund Policy Guideline.</strong>
            </p>
            <p>
              The consumer shall ensure that they select the appropriate
              products before finalizing the order.
            </p>
            <p>
              The consumer upon his/her/their purchase from the website warrants
              that no liability in this regard will arise against the website
              and in any case, shall not exceed Rs. 1,000/-.
            </p>
            <p>
              In case of an inherent manufacturing defect or damage during
              transit or transport, Bharat Business Innovations Pvt Ltd may
              exchange the product and provide a new product. However, the
              exchanged product will not be substituted for a different product.
            </p>
            <p>
              In case Bharat Business Innovations Pvt Ltd is unable to fulfil
              the order, the full amount shall be refunded.
            </p>
            <p>
              The Courts at Pune shall have exclusive jurisdiction to adjudicate
              upon any dispute.
            </p>
            <p>
              In case of any issue or query contact us at{" "}
              <a href="mailto:connect@bharatbusinessipl.com">
                connect@bharatbusinessipl.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundAndCancellation;
