import React, { useEffect, useState } from "react";
import axios from "axios";
import { singleservicebanner } from "../../Componants/api/api_base";
import { servicebanner } from "../../Componants/Image/ImportImg";
import { useParams } from "react-router-dom";
function SingleServiceBanner() {
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${singleservicebanner}/${id}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setBannerData(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching banner data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div
        id="carouselExample"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {loading ? (
            <div className="carousel-item active">
              <img
                src={servicebanner}
                className="d-block w-100"
                alt="Loading..."
              />
              <div className="overlay"></div>
              <div className="carousel-caption d-none d-md-block">
                {/* <h6 className="hero-title text-dark">
                  Indigenous Luxury Rooms With
                </h6> */}
              </div>
            </div>
          ) : (
            bannerData.map((item, index) => (
              <div
                key={item._id}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img
                  src={item.image}
                  className="d-block w-100 fit-img"
                  height={380}
                  alt={item.name}
                />
                <div className="overlay"></div>
                <div className="carousel-caption text-center">
                  <h1 className="">{item.title}</h1>
                </div>
              </div>
            ))
          )}
        </div>
        {!loading && bannerData.length > 1 && (
          <>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default SingleServiceBanner;
