import React from "react";
// import { useParams } from "react-router-dom";
import SingleServiceBanner from "./SingleServiceBanner";
import SingleServiceAboutus from "./SingleServiceAboutus";
import WhyChooseService from "./WhyChooseService";
import ServiceFeature from "./ServiceFeature";
import WhyCrm from "./WhyCrm";
import ServiceWorkflow from "./ServiceWorkflow";


function SingleService() {
    // const {id} = useParams()
  return (
    <>
      <SingleServiceBanner />
      <div className="container-fluid">
        <SingleServiceAboutus/>
        <WhyChooseService/>
         <ServiceFeature/>
         <WhyCrm/>
         <ServiceWorkflow/>
        </div>
    </>
  );
}

export default SingleService;
