import React from "react";
import "./Footer.css";
// import BlogFooter from "./BlogFooter";
import { Link } from "react-router-dom";
import { logo } from "../Image/ImportImg";

const Footer = () => {
  return (
    <>
      {/* <BlogFooter/> */}
      <div
        className="container-fluid text-white py-3"
        style={{ borderTop: "3px solid #00008b" }}
      >
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <a className="navbar-brand logo" href="/">
            <img src={logo} alt="" width="auto" height={100} className="img-fit"/>

            </a>
            <p className="text-dark">
            WeWork Enam Sambhav, <br /> C - 20, G Block Rd, G Block BKC, <br /> Bandra Kurla Complex, Bandra East,  Mumbai, Maharashtra 400051
            </p>

            <p className="text-dark">
              <i className="bi bi-telephone-fill"></i> +91 75065 15737
            </p>
            <p className="text-dark">
              <i className="bi bi-envelope-fill"></i>{" "}
              connect@bharatbusinessipl.com
            </p>
          </div>

          <div className="col-md-3 col-lg-3 text-dark">
            <h5 className="p-2 fw-bold" href="#">
              {" "}
              Information
            </h5>
            <div className="p-2 ">
              <Link className="text-dark" aria-current="page" to="/">
                <p className="mb-2">Home</p>
              </Link>
              <Link
                className="text-dark"
                aria-current="page"
                to="/about"
              >
                <p className="mb-2">About us</p>
              </Link>
              <Link
                className="text-dark"
                aria-current="page"
                to="/service"
              >
                <p className="mb-2 text-dark">Service</p>
              </Link>

              <Link
                className="text-dark"
                aria-current="page"
                to="/blog"
              >
                <p className="mb-2">Blogs</p>
              </Link>
              <Link
                className="text-dark"
                aria-current="page"
                to="/contact"
              >
                <p className="mb-2">Contact Us</p>
              </Link>
              <Link
                className="text-dark"
                aria-current="page"
                to="/terms_of_use"
              >
                <p className="mb-2">Terms of use</p>
              </Link>
              <Link
                className="text-dark"
                aria-current="page"
                to="/Privay_Policy"
              >
                <p className="mb-2">Privay Policy</p>
              </Link>
              <Link
                className="text-dark"
                aria-current="page"
                to="/Refund_And_Cancellation"
              >
                <p className="mb-2">Refund & Cancellation</p>
              </Link>
            </div>
          </div>

          <div className="col-md-3 col-lg-3 text-dark">
            <div className="p-2  ">
              <a
                href="https://www.facebook.com/amolsalunkhe19"
                target="blank"
              >
                <p className="mb-2">
                  <i className="bi bi-facebook"></i>{" "}
                  <span className="p-2 pt-0 pb-0">Facebook</span>
                </p>
              </a>
              <a
                href="https://www.instagram.com/amolsalunkhe19/"
                target="blank"
              >
                <p className="mb-2">
                  <i className="bi bi-instagram"></i>{" "}
                  <span className="p-2 pt-0 pb-0">Instagram</span>
                </p>
              </a>
              <a
                href="https://www.linkedin.com/company/bharat-business-innovations-pvt-ltd"
                target="blank"
              >
                <p className="mb-2">
                  <i className="bi bi-linkedin"></i>{" "}
                  <span className="p-2 pt-0 pb-0">Linkedin</span>
                </p>
              </a>
              {/* <a
                href="https://www.linkedin.com/company/bharat-business-innovations-pvt-ltd/?viewAsMember=true"
                target="blank"
              >
                <p className="mb-2">
                  <i className="bi bi-twitter-x"></i>{" "}
                  <span className="p-2 pt-0 pb-0">X.com</span>
                </p>
              </a> */}
              <p>Copyright © 2024 Bharat Business Innovations Pvt Ltd</p>
              <a
          href="https://store.zoho.in/ResellerCustomerSignUp.do?id=34df66f15973cb7207ce2ff3adb42629"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-style my-2">
          Get  Started
          </button>
        </a>
            </div>
          </div>
        </div>
      </div>
      <div className="whatsappicon">
        <a
          href="https://wa.me/7506515737"
          className="whatsapp-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-whatsapp text-success display-5"></i>
        </a>
      </div>
    </>
  );
};

export default Footer;
