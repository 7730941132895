import React, { useState } from "react";
import BlogApi from "./BlogApi";

const Blog = () => {
  return (
    <div>
      <div className="container-fluid bgImage">
        <div className="row justify-content-center">
          <div className="col-lg-11 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">Case Study</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-11 my-4">
           <h1 className="text-center fw-bold display-5 color">Read Our Case Study Here</h1>
            <div className="row justify-content-center">
              <BlogApi />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
