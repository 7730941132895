import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAllTeam } from "../../Componants/api/api_base";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Team.css"; // Ensure you have this CSS file for custom styles

const Team = ({ searchTeam }) => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedTeams, setExpandedTeams] = useState({});

  const getTeamApi = async () => {
    try {
      const response = await axios.get(
        `${getAllTeam}?page=&limit=&searchQuary=${searchTeam}`
      );
      setTeam(response.data);
    } catch (error) {
      console.error("Error fetching Team:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeamApi();
  }, [searchTeam]);

  const toggleDescription = (id) => {
    setExpandedTeams((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div>
      <div className="container-fluid bgImage mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-11 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">Our Team</h1>
            {/* <div className="text-white display-6">
              Seek quick advice from our complete professional and knowledgeable support functions in Aashirwad PG House.
            </div> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-11">
            <div className="row">
              {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <div key={index} className="col-6 col-lg-4 my-4">
                      <div className="rounded bg-color-second">
                        <div className="p-3 text-center">
                          <Skeleton circle height={250} width={250} />
                          <Skeleton width={120} height={20} className="mb-3" />
                          <Skeleton height={15} count={2} />
                        </div>
                      </div>
                    </div>
                  ))
                : team.map((member) => (
                    <div className="col-lg-4 my-4" key={member._id}>
                      
                        <div className="rounded bg-color-second Team-hover">
                          <div className="p-3 text-center">
                            <img
                              src={member.icon || member.image}
                              alt={member.title || "Team Icon"}
                              className="rounded-circle fit-img"
                              width={250}
                              height={250}
                            />
                          </div>
                          <div className="p-2 teambgcolor rounded-bottom">
                            <div className="fs-3 fw-bold">
                              {member.name || "No Name Available"}
                            </div>
                            <span className="color fs-6 fw-bold">
                              {member.position.toUpperCase() || "No Position Available"}
                            </span>
                            <div></div>
                            <p className="fs-5">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: expandedTeams[member._id]
                                    ? member.description || "No description available"
                                    : (member.description?.length > 145
                                        ? `${member.description.slice(0, 145)}...`
                                        : member.description) || "No description available",
                                }}
                                className="description-text"
                              />
                              {member.description && member.description.length > 145 && (
                                <span
                                  onClick={() => toggleDescription(member._id)}
                                  className="read-more-less"
                                >
                                  {expandedTeams[member._id] ? " Read Less" : " Read More"}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
