import React from "react";
import { crm, one,zoho2 } from "../../Componants/Image/ImportImg";
import './Home.css';

function HomeWhyus() {
  return (
    <div className="row bg-color3 my-5 justify-content-center p-3 p-lg-5">
      <div className="col-lg-5">
        <h4>All-in-one suite</h4>
        <div className="row">
          <div className="col-lg-6">
            <img src={one} className="img-fluid" alt="" />
          </div>
        </div>
        <h1>30 days free - Zoho Account.</h1>
        <p>
          Run your entire business on Zoho with our unified cloud software,
          designed to help you break down silos between departments and increase
          organizational efficiency.
        </p>
        <a
          href="https://store.zoho.in/ResellerCustomerSignUp.do?id=34df66f15973cb7207ce2ff3adb42629"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-style my-2">
            Start your free account today
          </button>
        </a>
      </div>
      <div className="col-lg-5 content-center my-2 border position-relative bg-white rounded shadow-sm">
        <div className=" ">
        <img src={crm} alt="" className="img-fluid pulse-animation" />
        <img src={zoho2} alt="" className="img-fluid position-absolute top-50 start-50 translate-middle" />

        </div>
       
        {/* <Lottie animationData={zoho} loop={true} /> */}
      </div>
    </div>
  );
}

export default HomeWhyus;
