import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import { about3 } from "../../Componants/Image/ImportImg"; // Fallback image
import { singleservicecrm } from "../../Componants/api/api_base";

function WhyCrm() {
  const [data, setData] = useState(null); // Initialize as null for single object
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${singleservicecrm}/${id}`)
      .then((response) => {
        setData(response.data); // Set data directly assuming it's an object
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="row my-5 justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-7 content-center">
              <div>
                <Skeleton height={50} width={300} className="mb-3" /> {/* Title loader */}
                <Skeleton count={4} /> {/* Description loader */}
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-5">
              <Skeleton height={250} width={400} /> {/* Image loader */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!data) {
    return <div>No data found.</div>; // Graceful fallback if no data
  }

  return (
    <div className="row my-5 justify-content-center">
      <div className="col-lg-10">
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-7 content-center">
            <div>
              <h1 className="color2 fw-bold">{data.title || "No Title"}</h1>
              <p dangerouslySetInnerHTML={{ __html: data.description || "No Description Available" }}></p>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5">
            <img
              src={data.image || about3}
              className="img-fluid rounded"
              alt={data.title || "Fallback Image"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyCrm;
