import React from "react";
import "./Home.css";
import HomeService from "./HomeService.jsx";
import HomeReview from "./HomeReview.jsx";
import HeroSecrion from "./HeroSecrion.jsx";
import HomeAbout from "./HomeAbout.jsx";
import HomeClient from "./HomeClient.jsx";
import HomeWhyus from "./HomeWhyus.jsx";

const Home = () => {
  return (
    <div>
      <HeroSecrion />
      <div className="container-fluid">
        <HomeAbout />
        {/* service */}
        <HomeService />
        {/* Review */}
        <HomeReview />
        <HomeWhyus />

        <HomeClient/>
      </div>
    </div>
  );
};

export default Home;
