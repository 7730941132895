import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Contact } from "../../Componants/api/api_base";
import { ContactImage } from "../../Componants/Image/ImportImg";

function ContactForm() {
  const history = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    description: "",
    number: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${Contact}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccess(true);
      toast.success("Thanks for contacting us!");

      setTimeout(() => {
        history("/contact");
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid bgImage mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-11 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">
              Contact <span className="color">Us</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div className="row">
          <h1 className="text-center fw-bold display-5 color">Get in Touch</h1>
          <h3 className="text-center">
            If You Have Any Query, Feel Free To Contact Us
          </h3>
          <div className="col-lg-6 content-center">
            <div className="">
              <img src={ContactImage} alt="image" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="bg-white rounded-5 p-3">
              <form onSubmit={handleSubmit}>
                <h3 className="text-center color fw-bold">Send Us a Message</h3>
                <div className="my-4">
                  <input
                    type="text"
                    required
                    name="name"
                    placeholder="Name"
                    className="form-control"
                    id="exampleInputName"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    id="exampleInputEmail1"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <input
                    name="number"
                    type="tel"
                    placeholder="Number"
                    className="form-control"
                    id="exampleInputEmail1"
                    value={formData.number}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <input
                    required
                    name="company"
                    type="text"
                    placeholder="Company"
                    className="form-control"
                    id="exampleInputCompany"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-4">
                  <textarea
                    name="description"
                    className="form-control"
                    placeholder="Message"
                    rows="3"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn-style"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="spinner-border color3" size="sm" />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row justify-content-between my-5">
          <div className="col-lg-6">
            <div
              className="rounded bg-white shadow-sm text-center p-3"
              style={{ borderTop: "5px solid #00008b" }}
            >
              <i className="bi bi-telephone-fill fs-2 color"></i>
              <h5>Call to ask any question</h5>
              <p>
                Zoho & Business Consultancy: +91 75065 15737 <br />
                Jobs, HR & Recruitment: +91 99203 73355
              </p>
              <div>
                <p>
                  <i className="bi bi-envelope-fill color"></i>
                  <a href="mailto:connect@bharatbusinessipl.com">
                    {" "}
                    connect@bharatbusinessipl.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3">
            <div className="rounded bg-white shadow-sm text-center p-3" style={{borderTop:"5px solid #00008b"}}>
             
             
            </div>
          </div> */}
          <div className="col-lg-6">
            <div
              className="rounded bg-white shadow-sm text-center p-3"
              style={{ borderTop: "5px solid #00008b" }}
            >
              <i className="bi bi-geo-alt-fill fs-2 color"></i>
              <h5>Our office</h5>
              <p>
                WeWork Enam Sambhav, C - 20, G Block Rd, <br /> G Block BKC,
                Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra 400051
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30167.881475740855!2d72.862787!3d19.064389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x461427e9c26a0b57%3A0xe2b954b4cf1f5a17!2sBharat%20Business%20Innovations%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1725172164395!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                width={"100%"}
                height={"500"}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
