import React,{useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Componants/Navbar/Navbar";
import Footer from "./Componants/Footer/Footer";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import ContactUs from "./Pages/Contact_us/Contact_us";
import Blog from "./Pages/Blog/Blog";
import SingleBlog from "./Pages/Blog/Single_blog";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import TopToScroll from "./TopToScroll";
import Service from "./Pages/Service/Service";
import Team from "./Pages/Team/Team";
import SingleService from "./Pages/Service/SingleService";
import TermAndCondition from "./Pages/Policy/Termandcondition";
import PrivayPolicy from "./Pages/Policy/PrivayPolicy";
import RefundAndCancellation from "./Pages/Policy/RefundAndCancelation";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function App() {
  const location = useLocation(); 
  useEffect(() => {
    ReactGA.initialize("G-KSFL2CJT1D"); 
    ReactGA.send("pageview"); 
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <Navbar />
      <TopToScroll />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service/:id" element={<SingleService />} />
        <Route path="/:id" element={<SingleBlog />} />
        <Route path="/team" element={<Team />} />
        <Route path="/terms_conditions" element={<TermsAndConditions />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms_of_use" element={<TermAndCondition />} />
        <Route path="/Privay_Policy" element={<PrivayPolicy />} />
        <Route
          path="/Refund_And_Cancellation"
          element={<RefundAndCancellation />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
