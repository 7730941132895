import React, { useState, useEffect } from "react";
import axios from "axios";
import { Client } from "../../Componants/api/api_base";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function HomeClient({ searchGallery }) {
  const [Gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);

  const getGalleryApi = async () => {
    try {
      const response = await axios.get(
        `${Client}?page=&limit=&searchQuary=${searchGallery}`
      );
      setGallery(response.data);
    } catch (error) {
      console.error("Error fetching Gallery:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGalleryApi();
  }, [searchGallery]);

  return (
   <>
    <div className="row bg-color py-3">
       <h1 className="text-center fw-bold display-5 text-white">
       Your Business <br /> Holistic  Growth  Partner
        </h1>
    </div>
    <div className="row justify-content-center my-5">
      <div className="col-md-11">   
        <div className="row">
          {loading
            ? Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="col-3 col-lg-1 my-5">
                  <div className="rounded bg-color-second">
                    <div className="p-4">
                      <Skeleton width={120} height={100} className="mb-3" />
                      
                    </div>
                  </div>
                </div>
              ))
            : Gallery.map((Gallery, index) => (
                <div className="col-3 col-lg-1 my-2 content-center" key={Gallery._id || index}>
                  <img src={Gallery.image} alt="" className="img-fluid rounded" />
                    {/* <span>{Gallery.name}</span> */}
                </div>
              ))}
        </div>
      </div>
    </div>
   </>
  );
}

export default HomeClient;
