import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAllservice } from "../../Componants/api/api_base";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Service.css";
import { Link } from "react-router-dom";

const Service = ({ searchservice }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const getServiceApi = async () => {
    try {
      const response = await axios.get(
        `${getAllservice}?page=&limit=&searchQuary=${searchservice}`
      );
      setServices(response.data);
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getServiceApi();
  }, [searchservice]);


  return (
    <div>
      <div className="container-fluid bgImage">
        <div className="row justify-content-center">
          <div className="col-lg-11 text-center my-5 py-lg-5 py-3">
            <h1 className="display-1 fw-bold text-white">Services</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-11">
            <h1 className="text-center fw-bold display-5 color my-5">
              Services We Provide
            </h1>
            <div className="row">
              {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <div key={index} className="col-6 col-lg-4 my-3">
                      <div className="rounded bg-color-second">
                        <div className="p-4 text-center">
                          <Skeleton
                            circle
                            height={100}
                            width={100}
                            className="mb-4"
                          />
                          <Skeleton width={100} height={20} className="mb-3" />
                          <Skeleton height={15} count={2} />
                        </div>
                      </div>
                    </div>
                  ))
                : services.map((service, index) => (
                    <div className="col-lg-4 my-5" key={service._id || index}>
                      <Link to={`/service/${service.slug}`}>
                        {" "}
                        <div className="rounded-4 bg-color text-center service text-white">
                          <div className="">
                            <img
                              src={service.icon || service.image}
                              alt={service.title || "Service Icon"}
                              className="rounded-circle mb-4 fit-img service-image"
                              width={100}
                              height={100}
                            />
                          </div>
                          <div className="text-center p-2">
                            <h4 className="text-center">
                              {service.title || "No Title Available"}
                            </h4>
                            <p className="text-center">
                              {service.description?.length > 170 ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `${service.description.slice(
                                      0,
                                      170
                                    )}...`,
                                  }}
                                />
                              ) : (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      service.description ||
                                      "No description available",
                                  }}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
