import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAllservice } from "../../Componants/api/api_base";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function HomeService({ searchservice }) {
  const [service, setservice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedServices, setExpandedServices] = useState({});

  const getserviceApi = async () => {
    try {
      const response = await axios.get(
        `${getAllservice}?page=&limit=&searchQuary=${searchservice}`
      );
      setservice(response.data);
    } catch (error) {
      console.error("Error fetching service:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getserviceApi();
  }, [searchservice]);

  const toggleDescription = (id) => {
    setExpandedServices((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="row justify-content-center bg-color py-5">
      <div className="col-md-11">
        <h1 className="text-center fw-bold display-5 text-white">Our Services</h1>
        <div className="row">
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="col-lg-4 my-4">
                  <div className="rounded bg-white">
                    <div className="p-4">
                      <Skeleton
                        circle
                        height={135}
                        width={135}
                        className="mb-4"
                      />
                      <Skeleton width={100} height={20} className="mb-3" />
                      <Skeleton height={15} count={2} />
                    </div>
                  </div>
                </div>
              ))
            : service.slice(0, 6).map((service, index) => (
                <div className="col-lg-4 my-5" key={service._id || index}>
                  <div className="rounded-4 bg-white text-center">
                    <div className="">
                      <img
                        src={service.icon || service.image}
                        alt={service.title || "Service Icon"}
                        className="rounded-circle mb-4 fit-img service-image"
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="text-center p-2">
                      <h4 className="text-center">
                        {service.title || "No Title Available"}
                      </h4>
                      <p className="text-center">
                        {expandedServices[service._id] ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                service.description ||
                                "No description available",
                            }}
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                service.description?.slice(0, 150) ||
                                "No description available",
                            }}
                          />
                        )}
                        {service.description &&
                          service.description.length > 150 && (
                            <span
                              onClick={() => toggleDescription(service._id)}
                              style={{
                                color: "#00008B",
                                cursor: "pointer",
                              }}
                            >
                              {expandedServices[service._id]
                                ? " Read Less"
                                : " Read More"}
                            </span>
                          )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

          <div className="text-center">
            <Link to={`/service`}>
              {" "}
              <button className="btn-style-2">View More</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeService;
