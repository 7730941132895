import React from "react";
import { amol, sandeep } from "../../Componants/Image/ImportImg";
import "./Team.css";

const Team = () => {
  return (
    <>
      <div className="container-fluid my-5 bg-color py-5">
        <div className="row justify-content-center">
          {/* <h3 className="text-center text-white">Team Members</h3> */}
          <h1 className="text-center fw-bold display-5 text-white">
            Professional Staff Ready to Help <br /> Your Business
          </h1>
          <div className="col-lg-4">
            <div className="card text-center border-0 ">
              <div className="position-relative">
                <img
                  src={sandeep}
                  alt="Person Image"
                  className="card-img-top img-fit"
                  height={300}
                />
                <div className="overlay d-flex justify-content-center align-items-center">
                  <a
                    href="https://www.linkedin.com/in/saandeep-n-patkar-08377160/"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="bi bi-linkedin color2 fs-1"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/saandeepnpatkar"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="bi bi-facebook color fs-1"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/saandeepnpatkar/"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="bi bi-instagram color3 fs-1"></i>
                  </a>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Saandeep N Patkar</h5>
                <p className="card-text">
                  Business & Life Mentor | Zoho CRM | WhatsApp Automation | Book
                  Publisher | Author | Consultant | NLP
                </p>
                <button
                  className="btn-style"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card text-center border-0">
              <div className="position-relative">
                <img
                  src={amol}
                  alt="Person Image"
                  className="card-img-top img-fit"
                  height={300}
                />
                <div className="d-flex justify-content-center align-items-center overlay">
                  <a
                    href="https://www.linkedin.com/in/amolsalunkhe19/"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="bi bi-linkedin fs-1 color2"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/amolsalunkhe19"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="bi bi-facebook fs-1 color"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/amolsalunkhe19/"
                    target="_blank"
                    className="social-icon"
                  >
                    <i className="bi bi-instagram fs-1 color3"></i>
                  </a>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Amol Salunkhe</h5>
                <p className="card-text">
                  The Sniper Recruiter | Integrator | Business Operations
                  Automation Expert | Consultant
                </p>
                <button className="btn-style" data-bs-toggle="modal"
                  data-bs-target="#exampleModal2">Read More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* model one */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3 color2" id="exampleModalLabel">
                Saandeep N Patkar
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Saandeep has experience in the corporate world as well as the
                manufacturing industry.
              </p>
              <p>
                He has actively worked for over 20 years with powerhouses such
                as
              </p>
              <h5>ICICI Lombard GIC Limited</h5>
              <h5>Bharti AXA General Limited</h5>
              <h5>Mahindra Logistics</h5>
              <h5>American Express</h5>
              <h5>Optus Telecom Australia</h5>
              <p>
                Saandeep also experienced massive success with his plastic
                manufacturing company in Silvassa. Saandeep believes that
                selling is a form of experiencing spirituality if done right.
              </p>
              <p>
                With this mantra, he has trained over{" "}
                <b>
                  7,000 people throughout his career and coached over 400
                  businessmen,
                </b>{" "}
                and it is for that very reason that he is our very own Spiritual
                Seller.
              </p>
              <p>
                He has explained this concept in more detail in his book, The
                Spiritual Seller.
              </p>
              <p>
                His success is an outcome of his zeal to solve complex
                challenges in a simple workable way using his Six Sigma as well
                as NLP techniques.
              </p>
              <p>
                He has an unwavering commitment to quality, deadlines and
                meeting clients’ goals.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* model two*/}
      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3 color3" id="exampleModalLabel">
                Amol Salunkhe
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h5>"Execution is a Game Play it and Enjoy it ~ Amol"</h5>

              <p>
                Introducing Amol Salunkhe, the Catalyst for Transformation and
                TheSniperRecruiter
              </p>
              <p>
                I have 15 years of Global HR Operations and Recruitment Business
                Experience.
              </p>
              <p>
                I have hired more than 1000 people for different companies,
                including technical and non-technical people.
              </p>
              <h5>
                Worked as a recruitment consulting partner for companies Wipro,
                Infosys, Rakuten, Deloitte, BigRock, Blackrock, Ogilvy, DDB, Leo
                Burnett, Dentsu, WPP Plc, Omnicom Group, Publicis Groupe, and
                Dentsu Inc.
              </h5>
              <p>
                Amol believes India is a superhouse of people's energy, and we
                can use that energy to cultivate acumen through effective
                recruitment strategies aligned with organizational objectives.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
